import { useEffect, useState, useRef } from "react";

export default function Question(props) {

    const [currentSection, setCurrentSection] = useState("")
    const [isAnswered, setIsAnswered] = useState(false)
    const [note, setNote] = useState("")
    const [background, setBackground] = useState("")
    const [imgRequired, setImgRequired] = useState("")
    const [photo, setPhoto] = useState("")
    const ref = useRef();

    useEffect(() => {
        if (props.q.answered) {
            setIsAnswered(true)
            setBackground("silver")
            if (props.q.response != props.q.correctResponse) {
                setImgRequired("#f2a2c4")
            } else {
                setImgRequired("")
            }
        } else {
            setIsAnswered(false)
            setBackground("")
            setImgRequired("")
        }

        if(props.q.note){
            setNote(props.q.note)
        }else{
            setNote("")
        }

        if (props.q.imageKey) {
            setPhoto(props.q.imageKey.name)
        } else {
            setPhoto("")
        }
    }, [props])

    const update = async (id, response, newNote, img) => {
        if (img) {
            setPhoto(img.name)
            props.update(id, "", "", img);
        } else if (newNote) {
            setNote(newNote);
            props.update(id, "", newNote);
        } else {
            setIsAnswered(true)
            setBackground("silver")
            if (response != props.q.correctResponse) {
                setImgRequired("#f2a2c4")
            } else {
                setImgRequired("")
            }
            props.update(id, response)
        }
    }

    return (
        <tr style={{ backgroundColor: background }}>
            <td>{props.q.name}</td>
            <td><button disabled={isAnswered} onClick={() => update(props.idx, true)}>Yes</button></td>
            <td><button disabled={isAnswered} onClick={() => update(props.idx, false)}>No</button></td>
            <td><input type="text" placeholder="Any Notes....." onChange={(e) => update(props.idx, "", e.target.value)} value={note} /></td>
            <td style={{ backgroundColor: imgRequired, textAlign:"center" }}>
                {photo == "" ?
                    <input style={{ marginLeft: "20%" }} type="file" ref={ref} accepts="image/*" onChange={(e) => update(props.idx, "", "", e.target.files[0])} />
                    : <>{photo}</>}
            </td>
        </tr>
    )
}