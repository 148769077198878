import { useEffect, useState } from "react"
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Question from "./Question";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#f2a2c4",
  backgroundColor: "#fff",
  border: "1px solid #f2a2c4",
  "&:hover": {
    backgroundColor: "#f2a2c4",
    border: "1px solid #f2a2c4",
    color: "#fff",
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  color: "#73c5c7",
  backgroundColor: "#fff",
  border: "1px solid #73c5c7",
  "&:hover": {
    backgroundColor: "#73c5c7",
    border: "1px solid #73c5c7",
    color: "#fff",
  },
}));

export default function Section(props) {

  const [questions, setQuestions] = useState([])

  useEffect(() => {
    setQuestions(props.data.questions)
  }, [props])

  const updateQuestion = async (id, val, note, img) => {
    var updatedQs = {};
    if (img) {
      updatedQs = { ...questions[id], imageKey: img };
    } else if (note) {
      updatedQs = { ...questions[id], note: note };
    } else {
      updatedQs = { ...questions[id], answered: true, response: val };
      if (val == questions[id].correctResponse) {
        props.addScore()
      }
    }
    var tempQs = questions;
    tempQs[id] = updatedQs;
    //const newQs = [...questions.slice(0, id), updatedQs, ...questions.slice(id + 1)];
    setQuestions(tempQs);
  }

  return (
    <>
      <div className="questions">
        <h3>{props.data.section} Section</h3>
        <table>
          {questions.map(function (d, i) {
            return (
              <Question idx={i} q={d} section={props.data.section} update={updateQuestion} />
            );
          })}
        </table>
      </div>
      <div className="buttons">
        {props.idx != 0 ? <ColorButton variant="outlined" onClick={() => props.changeSection(questions, "back")}>
          Last Section
        </ColorButton> : <></>}
        {props.idx != props.sections - 1 ? (
          <>
            <ColorButton variant="outlined" onClick={() => props.changeSection(questions, "next")}>
              Next Section
            </ColorButton>
          </>
        ) : (
          <SubmitButton variant="outlined" onClick={() => props.handleSubmit(questions)}>
            Submit
          </SubmitButton>
        )}
      </div>
    </>
  )
}