import { useState, useEffect } from "react"
import ImageIcon from '@mui/icons-material/Image';
import HideImageIcon from '@mui/icons-material/HideImage';
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    maxHeight: "80vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "1rem",
    p: 4,
    overflowY: "scroll",
};

export default function ResultQuest(props) {

    const [data, setData] = useState({})
    const [open, setOpen] = useState(false)
    const [isCorrect, setIsCorrect] = useState("")
    const [actualResponse, setActualResponse] = useState("")

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        setData(props.q)
        if (props.q.response == props.q.correctResponse) {
            setIsCorrect("✔")
        } else {
            setIsCorrect("❌")
        }
        if (props.q.response == true) {
            setActualResponse("Yes")
        } else if (props.q.response == false) {
            setActualResponse("No")
        } else {
            setActualResponse("N/A")
        }
        handleClose(false)
    }, [props])

    return (
        <tr>
            <td>{data.questionName}</td>
            <td>{actualResponse}</td>
            <td>{isCorrect}</td>
            <td>{data.note}</td>
            <td>
                {data.imageKey ?
                    <ImageIcon color="primary" fontSize="medium" onClick={handleOpen} />
                    :
                    <HideImageIcon color="primary" fontSize="medium" />
                }
            </td>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <img src={data.image} width="90%"/>
                </Box>
            </Modal>
        </tr>
    )
}