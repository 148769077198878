import './App.scss';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './components/Home';
import AuditResults from './components/audit/AuditResultsNew';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/audit/results" element={<AuditResults />} />
      </Routes>
      </BrowserRouter>
     
    </div>
  );
}

export default App;
