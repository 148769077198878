import { useEffect, useState } from 'react'
import axios from "axios";
import Header from '../Header';
import './results.css'
import ResultQuest from './ResultQuest';
export default function AuditResults() {

    const [data, setData] = useState([])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        let id = urlParams.get('id');
        axios.get(`https://franchise-back.tubbees.co.uk/audit/entry/new?id=${id}`).then((res) => {
            console.log(res.data)
            setData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    return (
        <>
            <Header />
            {data.timestamp ?
                <>
                    <div id="entryData">
                        <h2>Date of the visit: <span>{data.timestamp}</span></h2>
                        <h2>Carried Out By: <span>{data.staff}</span></h2>
                        <h2>Site: <span>{data.site}</span></h2>
                        <h2>Score: <span>{data.score}</span></h2>
                    </div>
                    <div id="results">
                        {data.results.map(function (d, i) {
                            return (
                                <>
                                    <h2 style={{ textAlign: "center" }}>{d.sectionName}</h2>
                                    <table>
                                        <tr id="tbHead">
                                            <td>Question</td>
                                            <td>Response</td>
                                            <td>Point</td>
                                            <td>Note</td>
                                            <td>Image</td>
                                        </tr>
                                        <tr>
                                        </tr>
                                        {d.questions.map(function (q, i) {
                                            return (
                                                <ResultQuest q={q}/>
                                            )
                                        })}
                                    </table>
                                </>
                            )
                        })}
                    </div>
                    <br />
                </>
                :
                <p>NO RESULTS COULD BE FOUND</p>
            }
        </>
    )
}