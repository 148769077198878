import logo from './images/Round-logo.webp'

export default function Header() {

    return(
        <header>
            <img src={logo} alt="logo" />
            <h1>Audit Form</h1>
        </header>
    )
}