import AuditForm from "./audit/AuditFormNew";
import Footer from "./Footer";
import Header from "./Header";

export default function Home() {
    return (
        <main className="home">
            <Header />
            <AuditForm />
            <Footer />
        </main>
    )
}