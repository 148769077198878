import { useState, useEffect } from "react";
import axios from "axios";
import "../styles/auditForm.scss";
import Section from "./sections/Section";

export default function AuditForm() {
  var date = new Date().toLocaleDateString('en-GB');
  const [sites, setSites] = useState([])
  const [sections, setSections] = useState([])
  const [score, setScore] = useState(0);
  const [auditor, setAuditor] = useState("");
  const [site, setSite] = useState("");
  const [answers, setAnswers] = useState([]);
  const [currentIdx, setCurrentIdx] = useState(0)
  const [totalQuestions, setTotalQuestions] = useState(0)

  useEffect(() => {
    axios.get("https://nitrous.rt-itservices.co.uk/sites/data")
      .then((res) => {
        setSites(res.data)
      }).catch((err) => {
        console.log(err)
      })
    axios.get("https://franchise-back.tubbees.co.uk/audit/questions")
      .then((res) => {
        setSections(res.data);
        var tempTotal = 0;
        for (let i = 0; i < res.data.length; i++) {
          tempTotal += res.data[i].questions.length;
        }
        setTotalQuestions(tempTotal)
      }).catch((err) => {
        console.log(err)
      })
  }, [])


  async function handleSubmit(questions) {
    var currentDate = Date.now();
    if (site == "" || auditor == "") {
      alert("Auditor And/Or Site Must Not Be Empty!")
    } else {
      var data = sections[currentIdx]
      data.questions = questions;
      var answers = [...sections.slice(0, currentIdx), data, ...sections.slice(currentIdx + 1)];
      var finalScore = ((score / totalQuestions) * 100).toFixed()

      for (let i = 0; i < answers.length; i++) {
        for (let q = 0; q < answers[i].questions.length; q++) {
          if (answers[i].questions[q].imageKey) {
            const data = { fileName: `${currentDate}_${answers[i].questions[q].questionId}.png` };
            await axios.post(`https://franchise-back.tubbees.co.uk/audit/photo-upload`, data).then(async (res) => {
              await axios.put(res.data, answers[i].questions[q].imageKey).then((resp) => {
                answers[i].questions[q].imageKey = data.fileName;
              }).catch((err) => {
                console.log(`Could not upload to S3: ${err.response.data}`);
              });
            }).catch((err) => {
              console.log(`Could not get the S3 link: ${err.response.data}`);
            });
          }
        }
      }

      var auditData = {
        staff: auditor,
        site: site,
        score: ` ${finalScore}% (${score}/${totalQuestions})`,
        results: answers
      }
      await axios.post("https://franchise-back.tubbees.co.uk/audit/submit/new", auditData)
        .then((res) => {
          alert("Audit submited")
          //window.location.reload()
        }).catch((err) => {
          console.log(err)
        })
    }
  }

  const changeSection = async (questions, direction) => {
    var data = sections[currentIdx]
    data.questions = questions;
    const newSection = [...sections.slice(0, currentIdx), data, ...sections.slice(currentIdx + 1)];
    setSections(newSection);
    if (direction == "next") {
      var bad = questions.filter((q) => q.response!=null && q.response != q.correctResponse)
      if (bad.length > 0) {
        var required = bad.filter((b) => !b.imageKey)
        if(required.length>0){
          alert(`MISSING ${required.length} PHOTOS!`)
        }else{
        setCurrentIdx(currentIdx + 1)
        }
      } else {
        setCurrentIdx(currentIdx + 1)
      }
    } else {
      setCurrentIdx(currentIdx - 1)
    }
  }

  const addScore = () => setScore(score + 1);

  return (
    <main className="auditForm">
      <section className="auditor">
        <h2>
          Date of visit: <span>{date}</span>
        </h2>
        <h2>
          Carried Out By:{" "}
          <input
            type="text"
            onChange={(e) => setAuditor(e.target.value)}
            placeholder="Your name..."
          />
        </h2>
        <h2>
          Site:{" "}
          <select onChange={(e) => setSite(e.target.value)}>
            <option>--Select One--</option>
            {sites.map(function (d, i) {
              return (
                <option>{d.name}</option>
              )
            })}
          </select>
        </h2>
        <h2 >
          Score:{" "}
          <span>
            {score}/{totalQuestions}
          </span>
        </h2>
      </section>
      <section className="form">
        {sections.length > 0 ?
          <Section idx={currentIdx} sections={sections.length} data={sections[currentIdx]} handleSubmit={handleSubmit} changeSection={changeSection} addScore={addScore} />
          : null}
      </section>
    </main>
  );
}